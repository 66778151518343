// 用户管理
<template>
    <div class="jobs">
        <div class="screening">
            <el-collapse v-model="activeNames" @change="handleChange">
                <el-collapse-item title="筛选框" name="1">
                    <div class="screening-out">
                        <el-form :inline="true" :model="searchFrom" class="demo-form-inline">
                            <el-row>
                                <el-col :span="22">
                                    <el-form-item label="关键字">
                                        <el-input v-model="searchFrom.keyword" placeholder="请输入需要查询的用户名称" size="mini"></el-input>
                                    </el-form-item>
                                    <!-- <el-form-item label="所属部门">
                                        <el-input v-model="searchFrom.name" placeholder="请输入需要查询的部门名称"></el-input>
                                    </el-form-item> -->
                                    <el-form-item label="创建时间">
                                        <el-date-picker
                                            v-model="valueTime"
                                            type="datetimerange"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            clearable
                                            value-format="YYYY-MM-DD HH:mm:ss"
                                            size="mini"
                                            :default-time="defaultTime">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="状态：" prop="">
                                        <el-select
                                        v-model="statusName"
                                        placeholder="请选择"
                                        style="width: 100%"
                                        clearable
                                        size="mini"
                                        >
                                        <el-option
                                            v-for="item in [{name:'全部',id:''},{name:'启用',id:0},{name:'禁用',id:1}]"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        >
                                        </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="2">
                                    <!-- <el-form-item> -->
                                        <div class="title-buttom">
                                            <el-button class="cancel" size="mini" @click="reset" round>重置</el-button>
                                            <el-button type="primary" size="mini" class="confirmAdd" @click="getDataList(true)" round>查询</el-button>
                                        </div>
                                    <!-- </el-form-item> -->
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <el-row>
            <el-col :span="12">
                <div class="title-name">
                    用户列表
                </div>
            </el-col>
            <el-col :span="12">
                <div class="title-buttom">
                    <el-tooltip content="添加用户" placement="top" effect="light">
                        <el-button class="editor" size="mini" icon="el-icon-plus" @click="addData" circle></el-button>
                    </el-tooltip>
                    <el-tooltip content="刷新列表" placement="top" effect="light">
                        <el-button class="editor" size="mini" icon="el-icon-refresh-right" @click="reset" circle></el-button>
                    </el-tooltip>
                    <!-- <el-button class="deletetable" size="mini" icon="el-icon-delete" @click="deleteData" round>删除</el-button> -->
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div>
                    <el-table
                    v-loading="loading"
                    ref="multipleTable"
                    :data="tableData"
                    tooltip-effect="dark"
                    style="width: 100%"
                    :max-height="tableHeight"
                    :header-cell-style="{background:'#e0e5ff',color:'#24252F'}"
                    @selection-change="handleSelectionChange">
                        <!-- <el-table-column
                        type="selection"
                        width="55">
                        </el-table-column> -->
                        <el-table-column
                        label="用户名">
                        <template #default="scope">{{ scope.row.username == null?'-': scope.row.username}}</template>
                        </el-table-column>
                        <el-table-column
                        label="手机号">
                        <template #default="scope">{{ scope.row.phone == null?'-': scope.row.phone}}</template>
                        </el-table-column>
                        <el-table-column
                        label="昵称">
                        <template #default="scope">{{ scope.row.nickname == null?'-': scope.row.nickname}}</template>
                        </el-table-column>
                        <el-table-column
                        label="头像">
                        <template #default="scope">
                            <img class="user-img" :src="scope.row.avatar == null||scope.row.avatar == ''?require(`@/assets/images/default.png`):scope.row.avatar" alt="">
                        </template>
                        </el-table-column>
                        <el-table-column
                        label="最近登陆时间">
                        <template #default="scope">{{ scope.row?.loginTime == null?'-': scope.row.loginTime}}</template>
                        </el-table-column>
                        <el-table-column
                        label="当前角色">
                        <template #default="scope">{{ scope.row.role?.name == null?'-': scope.row.role.name}}</template>
                        </el-table-column>
                        <el-table-column
                        label="所属部门">
                        <template #default="scope">{{ scope.row.department?.name == null?'-': scope.row.department.name}}</template>
                        </el-table-column>
                        <el-table-column
                        label="所在岗位">
                        <template #default="scope">{{ scope.row.station?.name == null?'-': scope.row.station.name}}</template>
                        </el-table-column>
                        <!-- <el-table-column
                        min-width="200" 
                        :show-overflow-tooltip="true"
                        label="备注">
                        <template #default="scope">{{ scope.row.remark == null?'-': scope.row.remark}}</template>
                        </el-table-column> -->
                        <el-table-column
                        label="启用/禁用" width="100">
                        <template #default="scope">
                            <div style="height:25px;" @click="onSwitch(scope.row, scope.row.status)">
                                <el-switch
                                style="display: block"
                                v-model="scope.row.status"
                                :active-value = '0'
                                :inactive-value = '1'
                                active-color="#637DFF"
                                inactive-color="#ff6e3e">
                                </el-switch>
                            </div>
                        </template>
                        </el-table-column>
                        <el-table-column label="操作" width="180">
                            <template #default="scope">
                                <el-tooltip content="编辑用户" placement="top" effect="light">
                                    <el-button class="editor" size="mini" icon="el-icon-edit" @click="modify(scope.row)" round></el-button>
                                </el-tooltip>
                                <el-tooltip content="删除用户" placement="top" effect="light">
                                    <el-button class="deletetable" size="mini" icon="el-icon-delete" round @click="confirmEvent(scope.row)"></el-button>
                                </el-tooltip>
                                <el-tooltip content="重置密码" placement="top" effect="light">
                                    <el-button class="confirmAdd" size="mini" icon="el-icon-lock" round @click="resetUserPassword(scope.row)"></el-button>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
        </el-row>
        <div class="pager">
            <div>
               <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                background
                :page-sizes="[10, 20, 30, 40]"
                :page-size="searchFrom.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </div>
        </div>
        <!-- 弹窗 -->
        <div class="popup-view">
            <el-form label-position="top" label-width="100px" :model="upform" :rules="rules" ref="upform">
                <el-dialog
                :title="popupTitle == 0?'新增用户':'修改用户'"
                v-model="centerDialogVisible"
                width="50%"
                @close='closeDialog'>
                <!-- 图标展示 -->
                <div class="popup-view">
                    <el-dialog
                        width="30%"
                        title="内弹窗"
                        v-model="innerVisible"
                        append-to-body>
                        <template #footer>
                            <el-form-item>
                                <el-button class="cancel" plain @click="innerVisible = false" round>取消</el-button>
                                <el-button class="confirmAdd" round>确认</el-button>
                            </el-form-item>
                        </template>
                    </el-dialog>
                </div>
                <div class="center-body">
                    <el-row :gutter="20">
                        <el-col :span="5">
                            <el-form-item label="头像：">
                                <img-upload :width="100" :height="100" :type="'1'" :img="upform.avatar" @success="handleImgSuccess" @delete="handleImgDelete"></img-upload>
                            </el-form-item>
                        </el-col>
                        <el-col :span="19">
                            <el-form-item label="昵称：" prop="nickname"> 
                                <el-input v-model="upform.nickname"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="用户名：" prop="username">
                                <el-input v-model="upform.username"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="所属部门："  prop="departmentId">
                                <div class="elCascader">
                                    <el-cascader
                                    v-model="departmentValue"
                                    :options="departmentTreeList"
                                    @change="handleChange"
                                    :props="{checkStrictly: true,value:'id',label:'name',children: 'children'}">
                                    </el-cascader>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <div v-if="popupTitle == 0">
                                <el-form-item label="密码：" prop="password">
                                    <el-input v-model="upform.password" show-password></el-input>
                                </el-form-item>
                            </div>
                            <div v-else>
                                <el-form-item label="密码：">
                                    <el-input v-model="upform.password" :disabled="true"></el-input>
                                </el-form-item>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="所在岗位："  prop="stationId">
                                <div class="elCascader">
                                    <el-cascader
                                    v-model="stationValue"
                                    :options="stationTreeList"
                                    @change="handleChange"
                                    :props="{checkStrictly: true,value:'id',label:'name',children: 'children'}">
                                    </el-cascader>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="手机号：" prop="phone">
                                <el-input v-model="upform.phone"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="当前角色："  prop="roleId">
                                <div class="elCascader">
                                    <el-cascader
                                    v-model="roleValue"
                                    :options="roleTreeList"
                                    @change="handleChange"
                                    :props="{checkStrictly: true,value:'id',label:'name',children: 'children'}">
                                    </el-cascader>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="用户备注：">
                                <el-input
                                type="textarea"
                                placeholder="请输入内容"
                                :autosize="{ minRows: 4, maxRows: 6}"
                                v-model="upform.remark"
                                maxlength="30"
                                show-word-limit
                                >
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <template #footer>
                        <el-form-item>
                            <el-button class="cancel" plain @click="resetForm('upform')" round>取消</el-button>
                            <el-button class="confirmAdd" @click="submitForm('upform')" round>{{ popupTitle == 0 ? '新增' : '修改' }}</el-button>
                        </el-form-item>
                </template>
                </el-dialog>
            </el-form>
        </div>
    </div>
</template>

<script>
import user from '@/api/system/user'
import department from '@/api/system/department'
import station from '@/api/system/station'
import role from '@/api/system/role'
import imgUpload from '@/components/imgUpLoad'
import { IconFont } from "@/utils/iconfont"
export default {
    components: {
        IconFont,
        imgUpload
    },
    data () {
        return {
            tableHeight:'',
            searchFrom:{
                page:1,
                pageSize:10,
                keyword:'',
                username:'',
                status:'',
                createTimeBegin:'',
                createTimeEnd:'',
            },//搜索值
            statusName:'',
            currentPage: 1,//分页
            total:0,
            multipleSelection: [],//选中集合
            departmentTreeList:[],//部门列表
            stationTreeList:[],//岗位列表
            roleTreeList:[],//角色列表
            departmentValue:[],//所属部门
            stationValue:[],//岗位部门
            roleValue:[],//角色部门
            valueTime:[],//选中时间段
            defaultTime: [
                new Date(2000, 1, 1, 12, 0, 0),
                new Date(2000, 2, 1, 8, 0, 0)
            ], //默认时间 '12:00:00', '08:00:00'
            activeNames: [],//折叠面板
            tableData: [],//数据列表
            loading: true,// loding 状态启用
            popupTitle:0,//弹窗类别
            centerDialogVisible: false,//弹窗启用
            innerVisible: false,//图标弹窗启用
            upform: {//用户信息
                username: '',//用户名称
                nickname:'',//昵称
                phone:'',//手机号
                departmentId:'',//所属部门
                stationId:'',
                roleId:'',
                password:'',
                remark: '',//用户备注
                avatar:null,//头像
            },
            rules: {
                username: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { min: 2, max: 15, message: '长度在 2-15 个字符', trigger: 'blur' }
                ],
                nickname: [
                    { required: true, message: '请输入昵称', trigger: 'blur' },
                    { min: 2, max: 8, message: '长度在 2-8 个字符', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    { min: 11, max:11, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入用户密码', trigger: 'blur' },
                    { min: 6, max:15, message: '用户密码长度为6-15个字符', trigger: 'blur' }
                ],
                departmentId: [
                    { required: true, message: '请选择所属部门', trigger: 'blur' },
                ],
                stationId: [
                    { required: true, message: '请选择所在岗位', trigger: 'blur' },
                ],
                roleId: [
                    { required: true, message: '请选择当前岗位', trigger: 'blur' },
                ],
            }
        }
    },
    created() {
        this.tableHeight = this.$store.state.tableHeight
    },
    watch: {

    },
    mounted() {
        this.getDataList(true)
        this.getDepartmentTree()
    },
    methods: {
        // 重置
        reset() {
            this.valueTime = []
            this.statusName = 0
            this.searchFrom = {
                page: this.currentPage,
                pageSize: 10, 
            }
            this.getDataList(true)
        },
        // 获取部门树
        getDepartmentTree(){
            department.getDepartmentTree({}).then((res) => {
                this.departmentTreeList = res.data
            })
            station.getStation({}).then((res) => {
                this.stationTreeList = res.data.records
            })
            role.getRoleTree({}).then((res) => {
                this.roleTreeList = res.data.records
            })
        },
        // 获取用户列表
        getDataList(type) {
            this.loading = type
            this.searchFrom.createTimeBegin = this.valueTime[0]
            this.searchFrom.createTimeEnd = this.valueTime[1]
            // this.statusName == '启用'?this.searchFrom.status = '0':this.searchFrom.status = '1'
            this.searchFrom.status = this.statusName
            user.getUserList({
                ...this.searchFrom
            }).then((res) => {
                this.tableData = res.data.records
                this.total = res.data.total
                this.loading = false
            })
        },
        // 分页
        handleSizeChange(val) {
            this.searchFrom.pageSize = val
            this.getDataList(true)
        },
        handleCurrentChange(val) {
            this.searchFrom.page = val
            this.currentPage = val
            this.getDataList(true)
        },
        // 折叠面板
        handleChange(val) {
            console.log(val);
        },
        // 多选
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 批量删除
        deleteData() {
            console.log(this.multipleSelection)
        },
        // 新增
        addData(){
            this.upform = {}
            this.departmentValue = []
            this.stationValue = []
            this.roleValue = []
            this.popupTitle = 0
            this.centerDialogVisible = true
        },
        // 控制状态
        onSwitch(row, status){
            user.editUser({
                uid:row.uid,
                status: status == 0 ? 0 : 1
            }).then((res) => {
                this.$notify({
                    title: '成功',
                    message: status == 0 ? '该用户启用成功！' : '该用户禁用成功！',
                    type: 'success'
                });
                this.getDataList(false)
                this.centerDialogVisible = false
            })
        },
        stringToArr(string){
            return string.split(',').filter(x => x != '')
        },
        // 修改
        modify(row) {
            // 获取当前行详情
            user.getUser({
                id:row.uid,
            }).then((res) => {
                this.upform = res.data
                this.departmentValue = this.stringToArr(res.data.department.path)
                this.departmentValue.push(res.data.department.id)
                this.stationValue.push(res.data.station.id)
                this.roleValue.push(res.data.role.id)
                this.popupTitle = 1
                this.centerDialogVisible = true
            })
        },
        // 删除确认
        confirmEvent(row) {
            this.$confirm('将删除该用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                user.deleteUser({
                    id:row.uid,
                }).then((res) => {
                    this.$notify({
                        title: '成功',
                        message: '用户删除成功！',
                        type: 'success'
                    });
                    this.getDataList(true)
                })
            }).catch(() => {
                this.$notify.info({
                    title: '通知',
                    message: '已取消删除!'
                });
            });
        },
        // 重置用户密码
        resetUserPassword(row){
            this.$confirm('将重置该用户登陆密码, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                user.resetPassword({
                    id:row.uid,
                }).then((res) => {
                    this.$notify({
                        title: '成功',
                        message: '用户密码重置为 88888888',
                        type: 'success'
                    });
                    this.getDataList(true)
                })
            }).catch(() => {
                this.$notify.info({
                    title: '通知',
                    message: '已取消重置!'
                });
            });
        },
        // 取消
        cancelEvent() {
            console.log("cancel!");
        },
        // 表单提交
        submitForm(formName) {
            this.upform.departmentId = this.departmentValue[this.departmentValue.length-1]
            this.upform.stationId = this.stationValue[this.stationValue.length-1]
            this.upform.roleId = this.roleValue[this.roleValue.length-1]
            this.$refs[formName].validate((valid) => {
            if (valid) {
                if( this.popupTitle == 0 ){
                    user.addUser({
                        ...this.upform
                    }).then((res) => {
                        this.$notify({
                            title: '成功',
                            message: '用户添加成功！',
                            type: 'success'
                        });
                        this.getDataList(true)
                        this.centerDialogVisible = false
                    })
                }else{
                    user.editUser({
                        ...this.upform
                    }).then((res) => {
                        this.$notify({
                            title: '成功',
                            message: '用户修改成功！',
                            type: 'success'
                        });
                        this.getDataList(true)
                        this.centerDialogVisible = false
                    })
                    
                }
            } else {
                // console.log('error submit!!');
                return false;
            }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.value = []
            this.centerDialogVisible = false
        },
        closeDialog() {
            this.$refs.upform.resetFields()
            this.value = []
            this.centerDialogVisible = false
        },
        // 头像上传
        handleImgSuccess(val) {
            this.upform.avatar = val
        },
        handleImgDelete() {
            this.upform.avatar = null
        }
    }
}
</script>

<style lang="scss" scoped>
.pager {
    display: flex;
    justify-content: flex-end;
}
.jobs{
    height: 100%;
    overflow-y: overlay;
    padding-right: 18px;
    .title-name{
        font-size: .9375rem;
        font-weight: 600;
        color: #303133;
    }
    .top-first{
        .search-box{
            width: 18.75rem;
        }
    }
}
.screening{
    margin-bottom: .9375rem;
}
.el-row {
    margin-bottom: .9375rem;
    &:last-child {
        margin-bottom: 0;
    }
}
.space-tile{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.title-buttom{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}
.editor{
    color: #0170ED;
    background: #E3F0FF;
}
.center-body{
    margin: 0 1.875rem;
}
.deletetable{
    color: #FF845B;
    background: #FFE4DB;
}
.deletetable-other{
    margin-right: 1.25rem;
}
.confirmAdd{
    background: #637DFF;
    color: #fff;
}
.cancel{
    color: #637DFF;
    border: #637DFF solid 1px;
}
.screening-out{
    margin: 0 .9375rem;
}
.showIcon{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    overflow-y: overlay;
    height: 28.125rem;
    .out-icon{
        border-radius: .5rem;
        .icon-box{
            display: flex;
            justify-content: space-between;
            align-items: center;
            justify-content: center;
            width:  4rem;
            height: 3rem;
        }
        .icon-name{
            text-align: center;
            width: 4rem;
            height: 2.5rem;
            line-height: 2.5rem;
            
        }
    }
    
}
.out-icon:hover{
    background: #E3F0FF;
    color: #0170ED;
}
.user-img{
    border-radius: 50%;
    border: solid .125rem #707070;
    width: 2.4125rem;
    height: 2.4125rem;
}
</style>

<style lang="scss">
.jobs .el-collapse-item__content {
    padding-bottom: 0 !important;
}
// .jobs .el-button {
//     // border-color: #FFFFFF !important;
// }
</style>