// 岗位管理
import { Request } from '@/http/request'

// 岗位列表
export function getStation(parameter: any) {
  return Request.axiosInstance.post('/system/station/list', parameter)
}
// 岗位添加
export function getStationAdd(parameter: any) {
  return Request.axiosInstance.post('/system/station/add', parameter)
}
// 查看岗位详情
export function getStationDetails(parameter: any) {
  return Request.axiosInstance.post('/system/station/get', parameter)
}
// 修改岗位信息 
export function getStationEdit(parameter: any) {
  return Request.axiosInstance.post('/system/station/edit', parameter)
}
// 删除岗位
export function getStationDelete(parameter: any) {
  return Request.axiosInstance.post('/system/station/delete', parameter)
}

export default { getStation, getStationAdd, getStationDetails, getStationEdit, getStationDelete }