// 部门管理
import { Request } from '@/http/request'

// 部门树
export function getDepartmentTree(parameter: any) {
  return Request.axiosInstance.post('/system/department/tree', parameter)
}
// 添加部门
export function addDepartment(parameter: any) {
  return Request.axiosInstance.post('/system/department/add', parameter)
}
// 删除部门
export function deleteDepartment(parameter: any) {
  return Request.axiosInstance.post('/system/department/delete', parameter)
}
// 编辑部门 
export function editDepartment(parameter: any) {
  return Request.axiosInstance.post('/system/department/edit', parameter)
}
// 查看详情 
export function getDepartmentDetails(parameter: any) {
  return Request.axiosInstance.post('/system/department/get', parameter)
}

export default { getDepartmentTree, addDepartment, deleteDepartment, editDepartment, getDepartmentDetails }