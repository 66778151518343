<template>
    <div class="upload" :style="styleObj" @mouseenter="onEnter" @mouseleave="onLeave">
        <div v-if="!imgUrl" class="upload-icon">
            <label class="uplpad-content" :for="'file' + index">
                <i v-if="type == '1'" class="el-icon-plus" style="font-size: 15px"><em v-if="title">{{title}}</em></i>
                <!-- <img v-else-if="type == '2'" class="el-image" src="../assets/img/up.png" alt="">
                <img v-else-if="type == '3'" class="el-image" src="../assets/img/up2.png" alt=""> -->
            </label>
            <input type="file" :id="'file' + index" style="display: none" @change="handleFileChange" accept = "image/*">
        </div>
        <div v-if="imgUrl" class="img-container">
            <el-image :src="imgUrl" fit="cover"></el-image>
        </div>
        <div v-if="imgUrl" class="mask" :class="show">
            <i class="el-icon-delete" @click="onDeleteClick"></i>
        </div>
    </div>
</template>

<script>
import img from '@/api/common/img'
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        index: {
            type: [String, Number],
            default: '0'
        },
        width: {
            type: Number,
            default: 100
        },
        height: {
            type: Number,
            default: 100
        },
        img: {
            type: String,
            default: ''
        },
        ops: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ""
        },
        kind: {
            type: String,
            default: "system"
        }
    },
    data() {
        return {
            show: []
        }
    },
    computed: {
        styleObj() {
            const obj = {}
            obj.width = this.width + 'px'
            obj.height = this.height + 'px'
            return obj
        },
        imgUrl() {
            return this.img
        }
    },
    methods: {
        handleFileChange(e) {
            const file = e.target.files[0]
            const formData = new FormData()
            formData.append('file', file)
            if(this.kind == 'web'){
                img.imgWebUpload(formData).then(res => {
                    if (res) {
                        if(this.ops == ''){
                            this.$emit('success', res.data.url)
                        }else{
                            let newjson = [res.data.url,this.ops]
                            this.$emit('success', newjson)
                        }
                    }
                })
                e.target.value = ''
            }else{
                img.imgUpload(formData).then(res => {
                    if (res) {
                        this.$emit('success', res.data.url)
                    }
                })
                e.target.value = ''
            }
        },
        onDeleteClick() {
            this.$emit('delete',this.ops)
        },
        onEnter() {
            this.show = ['show']
        },
        onLeave() {
            this.show = []
        }
    }
}
</script>

<style scoped>
    .show{
        display :flex !important;
    }
    .upload{ 
        display: flex;
        background-color: #fefefe;
        border-radius :10px;
        position :relative;
    }
    .upload-icon:hover{
        border-color: #409EFF ;
    }
    .upload-icon{
        width: 100%;
        height: 100%;
        border: 1px dashed #ddd;
        border-radius :5px;
    }
    .uplpad-content{
        width: 100%;
        height: 100%;
        display :flex;
        justify-content :center;
        align-items :center;
    }
    .uplpad-content:hover{
        cursor: pointer;
    } 
    .el-icon-plus{
        font-size: 24px;
        color: #999;
    }
    .img-container{
        width: 100%;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
    }
    .el-image{
        width: 100%;
        height: 100%;
    }
    .avatar-box{
        border: solid 1px #303133;
    }
    .mask{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: rgba(0,0,0,0.4);
        display: none;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
    }
    .el-icon-delete{
        color: #ededed;
        font-size: 22px;
        cursor: pointer;
    }
    .el-icon-thumb{
        color: #ededed;
        font-size: 22px;
        cursor: pointer;
    }
</style>
