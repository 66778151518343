// 角色管理
import { Request } from '@/http/request'

// 角色列表
export function getRoleTree(parameter: any) {
  return Request.axiosInstance.post('/system/role/list', parameter)
}
// 添加角色
export function addRole(parameter: any) {
    return Request.axiosInstance.post('/system/role/add', parameter)
}
// 删除角色
export function deleteRole(parameter: any) {
    return Request.axiosInstance.post('/system/role/delete', parameter)
}
// 修改角色 
export function editRole(parameter: any) {
    return Request.axiosInstance.post('/system/role/edit', parameter)
}

export default { getRoleTree, addRole, deleteRole, editRole }